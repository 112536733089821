import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './App.css';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApplyFilters: (options: any) => void;
  currentOptions: any;
  selectedCurrency: string | 'USD';
}

interface FilterOption {
  value: string;
}

interface LocalOptions {
  main_activity: string[];
  main_category: string[];
  main_nationality: string[];
  tags: string[];
  minPrice: number;
  maxPrice: number;
  searchQuery: string;
}

const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose, onApplyFilters, currentOptions, selectedCurrency }) => {
  const { t } = useTranslation();

  const [localOptions, setLocalOptions] = useState<LocalOptions>({
    main_activity: [],
    main_category: [],
    main_nationality: [],
    tags: [],
    minPrice: 0,
    maxPrice: 1000,
    searchQuery: '',
    ...currentOptions,
  });

  const [showActivities, setShowActivities] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showNationalities, setShowNationalities] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [activities, setActivities] = useState<FilterOption[]>([]);
  const [categories, setCategories] = useState<FilterOption[]>([]);
  const [nationalities, setNationalities] = useState<FilterOption[]>([]);
  const [tags, setTags] = useState<FilterOption[]>([]);
  const [priceRange, setPriceRange] = useState({ minPrice: 0, maxPrice: 1000 });



    // Fetch min and max price values based on selectedCurrency
    useEffect(() => {
      const fetchPriceRange = async () => {
        try {
          const response = await axios.get('https://staging2.peaxel.me/wp-json/custom/v1/get-min-max-values');
          const data = response.data;
          
          const currencyKey = selectedCurrency === 'USD' ? 'USD_value' : 'EUR_value';
          const minPrice = data[currencyKey].min_value;
          const maxPrice = data[currencyKey].max_value;
  
          setPriceRange({ minPrice, maxPrice });
          setLocalOptions((prev) => ({
            ...prev,
            minPrice,
            maxPrice,
          }));
        } catch (error) {
          console.error('Error fetching price range:', error);
        }
      };
  
      fetchPriceRange();
    }, [selectedCurrency]);
  

  // Update local options when currentOptions changes
  useEffect(() => {
    setLocalOptions((prevOptions) => ({
      ...prevOptions,
      ...currentOptions,
    }));
  }, [currentOptions]);

  // Fetch filter options from the API
  useEffect(() => {
    const fetchFilterOptions = async (criteria: string, setter: React.Dispatch<React.SetStateAction<FilterOption[]>>) => {
      try {
        const response = await axios.get(`/wp-json/custom/v1/filter-fitting/?criteria=${criteria}`);
        setter(response.data);
      } catch (error) {
        console.error(`Error fetching ${criteria} options:`, error);
      }
    };

    fetchFilterOptions('main_activity', setActivities);
    fetchFilterOptions('main_category', setCategories);
    fetchFilterOptions('main_nationality', setNationalities);
    fetchFilterOptions('tags', setTags);
  }, []);

  // Handle checkbox or range changes, call `onApplyFilters` after setting options
  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setLocalOptions((prev: LocalOptions) => {
      let newOptions: LocalOptions;
      if (type === 'checkbox') {
        const currentArray = Array.isArray(prev[name as keyof LocalOptions]) ? prev[name as keyof LocalOptions] : [];
        newOptions = {
          ...prev,
          [name]: checked
            ? [...(Array.isArray(currentArray) ? currentArray : []), value]
            : (Array.isArray(currentArray) ? currentArray.filter((item: string) => item !== value) : [])
        };
      } else if (type === 'range') {
        newOptions = { ...prev, [name]: parseInt(value, 10) };
      } else {
        newOptions = { ...prev, [name]: value };
      }

      // Apply filters in real-time 
      console.log('newOptions_init', newOptions);
      onApplyFilters(newOptions);
      return newOptions;
    });
  }, [onApplyFilters]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocalOptions((prev: LocalOptions) => {
      const newOptions = { ...prev, searchQuery: value };
      if (value.length >= 3 || value.length === 0) {
        onApplyFilters(newOptions);
      }
      return newOptions;
    });
  };


  const renderCheckboxList = (options: FilterOption[], name: keyof LocalOptions) => {
    // Step 1: Map the options to include both the original value and the translated value
    const sortedOptions = options
      .map(option => ({
        value: option.value,
        translatedLabel: t(option.value), // Translate each country
      }))
      // Step 2: Sort the options alphabetically based on the translated labels
      .sort((a, b) => a.translatedLabel.localeCompare(b.translatedLabel));
  
    return (
      <div className="checkboxList">
        {sortedOptions.map((option, index) => (
          <label key={option.value} className={index >= 7 ? 'hiddenOption' : ''}>
            <span className="checkboxText">{option.translatedLabel}</span> {/* Show sorted translated label */}
            <input
              type="checkbox"
              name={name}
              value={option.value}
              checked={(localOptions[name] as string[]).includes(option.value)}
              onChange={handleInputChange}
            />
          </label>
        ))}
      </div>
    );
  };
  

  // Function to display how many checkboxes are ticked for each filter section
  const getCheckedCount = (name: keyof LocalOptions) => {
    const checkedOptions = localOptions[name] as string[];
    return checkedOptions.length > 0 ? `(${checkedOptions.length})` : '';
  };

  return (
    <div className={`filter-modal ${isOpen ? 'open' : ''}`}>
      <div className="search-container">
      &nbsp;<i className="fa-solid fa-magnifying-glass search-icon"></i>&nbsp;
  <input
    className="custom-input"
    type="text"
    placeholder={t('searchPlaceholder')} // Just the plain placeholder text
    value={localOptions.searchQuery}
    onChange={handleSearchInputChange}
  />
</div>
<div className="priceRange">
        <span className="filter-title">{t('avgValueFilter')}</span>
        <input
          type="range"
          name="minPrice"
          min={priceRange.minPrice}
          max={priceRange.maxPrice}
          value={localOptions.minPrice}
          onChange={handleInputChange}
        />
        <input
          type="range"
          name="maxPrice"
          min={priceRange.minPrice}
          max={priceRange.maxPrice}
          value={localOptions.maxPrice}
          onChange={handleInputChange}
        />
        <span className="priceRangeText">
          {selectedCurrency === 'USD' ? '$' : '€'}
          {localOptions.minPrice} - {selectedCurrency === 'USD' ? '$' : '€'}
          {localOptions.maxPrice}
        </span>
      </div>

      <div className="filterSection">
        <span className="filter-title" onClick={() => setShowCategories(!showCategories)}>
        <span>{t('mainCategory')}&nbsp;<span style={{ color: '#848483', fontWeight: '300'  }}>{getCheckedCount('main_category')}</span></span>{' '}
          {showCategories ? <i className="fa-solid fa-chevron-down" style={{ color: '#848484' }} /> : <i className="fa-solid fa-chevron-up" style={{ color: '#848484' }} />}
        </span>
        {showCategories && renderCheckboxList(categories, 'main_category')}
      </div>

      <div className="filterSection">
        <span className="filter-title" onClick={() => setShowActivities(!showActivities)}>
        <span>{t('mainActivity')}&nbsp;<span style={{ color: '#848483', fontWeight: '300'  }}>{getCheckedCount('main_activity')}</span></span>{' '}
          {showActivities ? <i className="fa-solid fa-chevron-down" style={{ color: '#848484' }} /> : <i className="fa-solid fa-chevron-up" style={{ color: '#848484' }} />}
        </span>
        {showActivities && renderCheckboxList(activities, 'main_activity')}
      </div>

      <div className="filterSection">
        <span className="filter-title" onClick={() => setShowNationalities(!showNationalities)}>
          <span>{t('mainNationality')}&nbsp;<span style={{ color: '#848483', fontWeight: '300'  }}>{getCheckedCount('main_nationality')}</span></span>{' '}
          {showNationalities ? <i className="fa-solid fa-chevron-down" style={{ color: '#848484' }} /> : <i className="fa-solid fa-chevron-up" style={{ color: '#848484' }} />}
        </span>
        {showNationalities && renderCheckboxList(nationalities, 'main_nationality')}
      </div>

      <div className="filterSection">
        <span className="filter-title" onClick={() => setShowTags(!showTags)}>
        <span>{t('tags')}&nbsp;<span style={{ color: '#848483', fontWeight: '300' }}>{getCheckedCount('tags')}</span></span>{' '}
          {showTags ? <i className="fa-solid fa-chevron-down" style={{ color: '#848483' }} /> : <i className="fa-solid fa-chevron-up" style={{ color: '#848484' }} />}
        </span>
        {showTags && renderCheckboxList(tags, 'tags')}
      </div>
    </div>
  );
};

export default FilterModal;
